import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import Scrollbar from 'react-perfect-scrollbar';
import { Accordion, NavLink } from 'react-bootstrap';

class Sidenav extends Component {
    navToggle = () => {
        document.getElementById('body').classList.toggle('ms-aside-left-open');
        document.getElementById('ms-side-nav').classList.toggle('ms-aside-open');
        document.getElementById('overlayleft').classList.toggle('d-block');
    }
    render() {
        return (
            <Fragment>
                {/* Overlays */}
                <div className="ms-aside-overlay ms-overlay-left ms-toggler" id="overlayleft" data-target="#ms-side-nav" data-toggle="slideLeft" onClick={this.navToggle} />
                {/* Sidebar Navigation Left */}
                <Scrollbar id="ms-side-nav" className="side-nav fixed ms-aside-scrollable ms-aside-left">
                    {/* Logo */}
                    <div className="logo-sn ms-d-block-lg" style={{ backgroundColor: '#fff' }}>
                        <Link className="pl-0 ml-0 text-center" to="/"> POOL STAKING</Link>
                    </div>
                    {/* Navigation */}
                    <Accordion defaultActiveKey="111" className="accordion ms-main-aside fs-14" id="side-nav-accordion">
                        <li className="menu-item" style={{ marginTop: '15px' }}>
                            <Link to="/"><i className="material-icons fs-16">tune</i>Home</Link>
                        </li>
                    </Accordion>
                    <Accordion defaultActiveKey="0" className="accordion ms-main-aside fs-14" id="side-nav-accordion">
                        <li className="menu-item" style={{ marginTop: '15px' }}>
                            <Link to="/snapshots"><i className="material-icons fs-16">tune</i>Snapshots</Link>
                        </li>
                    </Accordion>
                    <Accordion defaultActiveKey="12" className="accordion ms-main-aside fs-14" id="side-nav-accordion">
                        <li className="menu-item" style={{ marginTop: '15px' }}>
                            <Link to="/bonus"><i className="material-icons fs-16">tune</i>Bonus</Link>
                        </li>
                    </Accordion>
                    {/* <Accordion defaultActiveKey="4" className="accordion ms-main-aside fs-14" id="side-nav-4">
                        <li className="menu-item" style={{marginTop: '15px'}}>
                             <Link to="/campaign"><i className="material-icons fs-16">tune</i>Campaign</Link>
                        </li>
                    </Accordion> */}
                    <Accordion defaultActiveKey="1" className="accordion ms-main-aside fs-14" id="side-nav-1">
                        <li className="menu-item" style={{ marginTop: '15px' }}>
                            <Link to="/pools"><i className="material-icons fs-16">tune</i>Pools</Link>
                        </li>
                    </Accordion>
                    <Accordion defaultActiveKey="2" className="accordion ms-main-aside fs-14" id="side-nav-2">
                        <li className="menu-item" style={{ marginTop: '15px' }}>
                            <Link to="/apy-estimate"><i className="material-icons fs-16">tune</i>Apy Estimate</Link>
                        </li>
                    </Accordion>
                    <Accordion defaultActiveKey="13" className="accordion ms-main-aside fs-14" id="side-nav-accordion">
                        <li className="menu-item" style={{ marginTop: '15px' }}>
                            <Link to="/referrals"><i className="material-icons fs-16">tune</i>Referrals</Link>
                        </li>
                    </Accordion>

                    <Accordion defaultActiveKey="3" className="accordion ms-main-aside fs-14" id="side-nav-3">
                        <li className="menu-item">
                            <Accordion.Toggle as={NavLink} variant="link" eventKey="1" className="has-chevron">
                                <span><i className="material-icons fs-16">tune</i>Reports</span>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="1">
                                <ul id="basic-elements" aria-labelledby="basic-elements" data-parent="#side-nav-accordion">
                                    <li> <Link to="/transaction-reports">Transaction reports</Link> </li>
                                    <li> <Link to="/balance-reports">Balance reports</Link> </li>
                                    <li> <Link to="/download-reports">Download reports</Link> </li>
                                </ul>
                            </Accordion.Collapse>
                        </li>
                    </Accordion>

                    <Accordion defaultActiveKey="101" className="accordion ms-main-aside fs-14" id="side-nav-3">
                        <li className="menu-item">
                            <Accordion.Toggle as={NavLink} variant="link" eventKey="1" className="has-chevron">
                                <span><i className="material-icons fs-16">tune</i>Staking Platform APY</span>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="1">
                                <ul id="basic-elements" aria-labelledby="basic-elements" data-parent="#side-nav-accordion">
                                    <li> <Link to="/staking-platform-list">List</Link> </li>
                                    <li> <Link to="/staking-platform-apy-create">Create</Link> </li>
                                </ul>
                            </Accordion.Collapse>
                        </li>
                    </Accordion>

                    <Accordion defaultActiveKey="102" className="accordion ms-main-aside fs-14" id="side-nav-3">
                        <li className="menu-item">
                            <Accordion.Toggle as={NavLink} variant="link" eventKey="1" className="has-chevron">
                                <span><i className="material-icons fs-16">tune</i>Trading Platform</span>
                            </Accordion.Toggle>

                            <Accordion.Collapse eventKey="1">
                                <ul id="basic-elements" aria-labelledby="basic-elements" data-parent="#side-nav-accordion">
                                    <li> <Link to="/trading-platform-list">List</Link> </li>
                                    <li> <Link to="/trading-platform-create">Create</Link> </li>
                                </ul>
                            </Accordion.Collapse>
                        </li>
                    </Accordion>


                </Scrollbar>
            </Fragment>
        );
    }
}

export default Sidenav;