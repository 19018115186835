import { instance } from '../../apiBase/instance';

let authToken:any = window.localStorage.getItem('tokenId')

const AdminSummary = {


	getPoolCheck() {
		return instance.get<any>(`api/configs/pool-check`, {
			headers:{
                "Authorization": `Bearer ${authToken}`,
            }
		});
	},
	
	
};
export default  AdminSummary;