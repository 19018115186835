import { instance } from '../../apiBase/instance';

let authToken:any = window.localStorage.getItem('tokenId');

const TransactionLogs = {

	getSnapshotsList(param:any){
		return instance.get(`api/admins/snapshots`, {
			params: param,
			headers:{
                "Authorization": `Bearer ${authToken}`,
            }
		});
	},
	getTradingPlatformsList(param:any){
		return instance.get(`api/trading-platforms`, {
			params: param,
			headers:{
                "Authorization": `Bearer ${authToken}`,
            }
		});
	},
	getStakingPlatformList(param:any){
		return instance.get(`api/staking-platforms`, {
			params: param,
			headers:{
                "Authorization": `Bearer ${authToken}`,
            }
		});
	},
	getTransactionReports(param:any){
		return instance.get(`api/admins/transaction-reports`, {
			params: param,
			headers:{
                "Authorization": `Bearer ${authToken}`,
            }
		});
	},
	getRefLinks(param:any){
		return instance.get(`api/admins/ref-links`, {
			params: param,
			headers:{
                "Authorization": `Bearer ${authToken}`,
            }
		});
	},
	getRefDetails(id:any){
		return instance.get(`api/admins/ref-links/${id}`, {
			headers:{
                "Authorization": `Bearer ${authToken}`,
            }
		});
	},
	getBalancesReports(param:any){
		return instance.get(`api/admins/balance-reports`, {
			params: param,
			headers:{
                "Authorization": `Bearer ${authToken}`,
            }
		});
	},
	
	getApyEstimateList(param:any){
		return instance.get(`api/est`, {
			params: param,
			headers:{
                "Authorization": `Bearer ${authToken}`,
            }
		});
	},
	
	
	updateSnapshotReward(data:any) {
		
		return instance.put<any>(`api/admins/update-snapshot-reward` ,data, {
			headers:{
                "Authorization": `Bearer ${authToken}`,
            }
		});
	},
	publicSnapshotReward(data:any) {
		
		return instance.put<any>(`api/admins/publish-snapshot-reward` ,data, {
			headers:{
                "Authorization": `Bearer ${authToken}`,
            }
		});
	},
	updateSnapshotBonus(data:any) {
		
		return instance.put<any>(`api/admins/update-snapshot-bonus` ,data, {
			headers:{
                "Authorization": `Bearer ${authToken}`,
            }
		});
	},
	updateTradingPlatform(id:any, data:any) {
		
		return instance.put<any>(`api/admins/update-trading-platform/${id}` ,data, {
			headers:{
                "Authorization": `Bearer ${authToken}`,
            }
		});
	},
	deleteTradingPlatform(id:any) {
		
		return instance.delete<any>(`api/admins/delete-trading-platform/${id}` ,{
			headers:{
                "Authorization": `Bearer ${authToken}`,
            }
		});
	},
	updateStakingPlatform(id:any, data:any) {
		
		return instance.put<any>(`api/admins/update-staking-platform-apy/${id}` ,data, {
			headers:{
                "Authorization": `Bearer ${authToken}`,
            }
		});
	},
	deleteStakingPlatform(id:any) {
		
		return instance.delete<any>(`api/admins/delete-staking-platform-apy/${id}` ,{
			headers:{
                "Authorization": `Bearer ${authToken}`,
            }
		});
	},
	publicBonus() {
		
		return instance.put<any>(`api/admins/publish-bonus` ,{}, {
			headers:{
                "Authorization": `Bearer ${authToken}`,
            }
		});
	},
	publicApy() {
		
		return instance.put<any>(`api/admins/publish-apy` ,{}, {
			headers:{
                "Authorization": `Bearer ${authToken}`,
            }
		});
	},
	updateApr(data:any) {
		
		return instance.put<any>(`api/configs/update-apy` ,data, {
			headers:{
                "Authorization": `Bearer ${authToken}`,
            }
		});
	},
	setupKOLCode(data:any) {
		
		return instance.post<any>(`api/admins/kol-ref-link` ,data, {
			headers:{
                "Authorization": `Bearer ${authToken}`,
            }
		});
	},
	postFakeData(data:any) {
		
		return instance.post<any>(`api/admins/fake-deposit` ,data, {
			headers:{
                "Authorization": `Bearer ${authToken}`,
            }
		});
	},
	createStakingPlatformApy(data:any) {
		
		return instance.post<any>(`api/admins/create-staking-platform-apy` ,data, {
			headers:{
                "Authorization": `Bearer ${authToken}`,
            }
		});
	},
	createTradingPlatform(data:any) {
		
		return instance.post<any>(`api/admins/create-trading-platform` ,data, {
			headers:{
                "Authorization": `Bearer ${authToken}`,
            }
		});
	},
	exportTransaction(data:any) {
		
		return instance.post<any>(`api/admins/export-transaction-reports` ,data, {
			headers:{
				'Content-Type': 'blob',
                "Authorization": `Bearer ${authToken}`,
            }
		});
	},
	
	getConfigApy() {
		return instance.get<any>(`api/configs/apy`, {});
	},

	enableApy(id:any, data:any) {
		
		return instance.put<any>(`api/admins/active-snapshot-apy/${id}` ,data, {
			headers:{
                "Authorization": `Bearer ${authToken}`,
            }
		});
	},
	
};
export default TransactionLogs;