import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Link, Switch, useLocation } from 'react-router-dom';
import AprConfig from '../../pages/apr-config';
import ApyEstimate from '../../pages/apy-estimate';
import BalanceReport from '../../pages/balance-report';
import Bonus from '../../pages/bonus';
import Campaign from '../../pages/campaign';
import FakeData from '../../pages/fake-data';
import HomePage from '../../pages/Home';
import Pools from '../../pages/pools';
import Referrals from '../../pages/referrals';

import Snapshots from '../../pages/snapshots';
import StakingPlatformApyCreate from '../../pages/staking-platform-apy';
import StakingPlatformList from '../../pages/staking-platform-apy/list';
import TradingPlatformApyCreate from '../../pages/trading-platform';
import TradingPlatformList from '../../pages/trading-platform/list';
import TransactionReport from '../../pages/transaction-report';
import DownloadReport from '../../pages/download-report';


const Home = React.lazy(() => import("../../pages/Home"));

const Defaultlogin = React.lazy(() => import("../../pages/login/Defaultlogin"));


const Header = () => {

	const NavMenu = () => {
		return (
			<>
				<Router>
					<Suspense fallback={<></>}>
						<Switch>
							<Route exact path="/" component={HomePage} />
							<Route path="/bonus" component={Bonus} />
							<Route path="/snapshots" component={Snapshots} />
							<Route path="/apr-config" component={AprConfig} />
							<Route path="/campaign" component={Campaign} />
							<Route path="/pools" component={Pools} />
							<Route path="/apy-estimate" component={ApyEstimate} />
							<Route path="/fake-data" component={FakeData} />
							<Route path="/transaction-reports" component={TransactionReport} />
							<Route path="/balance-reports" component={BalanceReport} />
							<Route path="/download-reports" component={DownloadReport} />
							<Route path="/referrals" component={Referrals} />
							<Route path="/staking-platform-list" component={StakingPlatformList} />
							<Route path="/staking-platform-apy-create" component={StakingPlatformApyCreate} />
							<Route path="/trading-platform-list" component={TradingPlatformList} />
							<Route path="/trading-platform-create" component={TradingPlatformApyCreate} />
							<Route path="/login" component={Defaultlogin} />
						</Switch>
					</Suspense>
				</Router>
			</>
		);
	}
	return (
		<>
			<Router>
				<NavMenu />
			</Router>
		</>

	);
};

export default Header;
