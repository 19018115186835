import React, { useState, useEffect, useCallback, Fragment } from 'react';
import { BrowserRouter as Router, Route, Link, useHistory } from 'react-router-dom';
import Breadcrumb from './Breadcrumb';
import 'antd/dist/antd.css';
import { Row, Card, Pagination, Button, Tag, Table, Space, Input, Col, Select, message, Modal, Form, DatePicker, Spin } from 'antd';

import Navbar from '../../layouts/Navbar';
import Sidenav from '../../layouts/Sidenav';
import TransactionLogs from '../../../config/api/transactionLog';
import CopyToClipboard from "react-copy-to-clipboard";

const Referrals = () => {

  const [referrals, setReferrals] = useState<any>([]);
  const [referralDetails, setReferralDetails] = useState<any>([]);
  const [totalRow, setTotalRow] = useState(0);
  const [loading, setLoading] = useState(false);
  const [modalDetail, setModalDetail] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const [form] = Form.useForm();
  const [isAddCode, setIsAddCode] = useState(false);
  let authToken:any = window.localStorage.getItem('tokenId');


  const [param, setParam] = useState(
    {
      page: 1,
      size: 20,
      type: ''
    }
  );

  const getReferralts = async () => {
    setLoading(true);
    let res:any = await TransactionLogs.getRefLinks(param);
    setTotalRow(parseInt(res.data.total));
    if(res.status === 200 && res.data.data.length > 0){
      setReferrals(res.data.data);
      setLoading(false);
    }else{
      setReferrals([]);
      setLoading(false);
    }
  };

  const getReferraltDetail = async (id:any) => {
    setLoadingModal(true);
    let res:any = await TransactionLogs.getRefDetails(id);
    
    if(res.status === 200 && res.data.data.length > 0){
      
      setReferralDetails(res.data.data);
      setLoadingModal(false);
    }else{
      setReferralDetails([]);
      setLoadingModal(false);
    }
  };

  useEffect(() => {
    if(authToken){
      getReferralts();
    }
    
  }, [param, authToken]);

  function getFormattedDate(date:any) {
    let year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, '0');
    let day = date.getDate().toString().padStart(2, '0');
  
    return  year + '-' + month + '-' +day;
  }

  const showListRef = (id:any)=>{
    getReferraltDetail(id);
    setModalDetail(true);
  }
  const showAddCode = ()=>{
    setIsAddCode(true);
  }

  const columns: any = [
    {
      title: 'Date',
      dataIndex: 'created_at',
      render: (text:any, record:any) => {

        const createdAt = new Date(record?.created_at);
 
        let datTime = createdAt.getTime();
        let newDateTime = datTime - (8*60*60);
        let newDate = new Date(newDateTime); 
				return (
					<div className="text-gray">
            { getFormattedDate(newDate) }
          </div>
				)
			}
    },
    {
      title: 'Code',
      dataIndex: 'code',
      render: (text:any, record:any) => {
        return (
          <div className="text-black">
            { record?.code }
          </div>
        )}
    },
    {
      title: 'Type',
      dataIndex: 'type',
      render: (text:any, record:any) => {
        return (
          <div className="text-black">
            { record?.type }
          </div>
        )}
    },
    {
      title: 'Total users',
      dataIndex: 'total_users',
      render: (text:any, record:any) => {
                
				return (
					<div className="text-info">
            {new Intl.NumberFormat("ja-JP").format(record?.total_users)}
          </div>
				)
			}
    },
    {
      title: 'Points',
      dataIndex: 'points',
      render: (text:any, record:any) => {
                
				return (
					<div className="text-info">
            {new Intl.NumberFormat("ja-JP").format(record?.points)}
          </div>
				)
			}
    },
    
    
    {
      title: 'Detail',
      dataIndex: '',
      render: (text:any, record:any) => {
        let baseUrl:any = process.env.REACT_APP_BASE_URL;
				return (
					<div className="text-info">
            <Button htmlType='button' type='primary' onClick={()=>{showListRef(record?.id)}}>Detail</Button>
            <CopyToClipboard onCopy={onCopy} text={`${baseUrl}?ref=${record?.code}`}>
              <Button htmlType='button' style={{marginLeft: '5px'}} danger type='primary'>Copy Ref</Button>
            </CopyToClipboard>
            
          </div>
				)
			}
    }
  ];

  const columnsModal: any = [
    
    {
      title: 'User Address',
      dataIndex: 'address',
     
      render: (text:any, record:any) => {
        return (
          <div className="text-black">
            {record?.address ? `${record?.address.substring(0, 10)}...${record?.address.substring(record?.address.length - 10)}` : "---"}
          </div>
        )}
    }
  ];

  

  const onChange_Pagi = (pageNumber:any) => {
    console.log(pageNumber);
    setParam({
      ...param,
      page: pageNumber
    });
  };

  const onFinish = async(values: any) => {
    
    let data:any = {
      "code": values?.code.toUpperCase()
    }
    await TransactionLogs.setupKOLCode(data).then((res:any)=>{

      console.log('res', res);
      message.success('Add Successfully!');
      getReferralts();
      setIsAddCode(true);
      
    }).catch((error)=>{
      message.error('Add Failed!');
    });
  
  };

  const onCopy = ()=>{
    message.success('Copied!');
  }

  const handleChangeSlt = (value: string) => {

    setParam({
      ...param,
      type: value
    });
  };

  return (
    <>
      <div className="body ms-body ms-aside-left-open ms-primary-theme ms-has-quickbar" id="body">
        <Sidenav />
        <main className="body-content">
          <Navbar />
          <div className="ms-content-wrapper">
            <div className="row">
              <Breadcrumb />
              <Card 
                title="Referrals" style={{ width: '100%' }}
                extra={
                  <>
                    <Select
                      style={{ width: 190, marginRight:'5px' }}
                      placeholder="Select type user"
                      onChange={handleChangeSlt}
                      options={[
                        { value: 'USER', label: 'USER' },
                        { value: 'KOL', label: 'KOL' }
                      ]}
                    />
                    <Button htmlType='button' type='primary' onClick={showAddCode} danger>Add Code</Button>
                  </>
                }
              >
            
                <div className="">
                    {loading ? (
                      <><div className='text-center'><Spin size='large'/></div></>
                    ):(
                      <>
                        <Table columns={columns} dataSource={referrals} pagination={false}/>
                        {Number(totalRow) > Number(param?.size) && (
                          <Pagination
                              current={param.page}
                              defaultCurrent={1}
                              total={totalRow}
                              pageSize={param.size}
                              onChange={onChange_Pagi}
                              showSizeChanger={false}
                              style={{marginTop: '15px'}}
                              className="pagiation-custom mt-15"
                          />
                        )}
                      </>
                    )}
                    
                </div>
              </Card>
            </div>
          </div>
        </main>
      </div>
      <Modal title="Referrals Detail" footer={false} visible={modalDetail} onOk={()=>{ setModalDetail(false); setReferralDetails([])}} onCancel={()=>{ setModalDetail(false); setReferralDetails([])}}>
        {loadingModal ? (
          <>
            <div className='text-center'><Spin size='large'/></div>
          </>
        ):(
          <>
            <Table columns={columnsModal} dataSource={referralDetails}/>
          </>
        )}
        
      </Modal>

      <Modal title="Code" footer={false} visible={isAddCode} onOk={()=>{ setIsAddCode(false); form.resetFields()}} onCancel={()=>{ setIsAddCode(false); form.resetFields()}}>
        <Form
          name="basic"
          form={form}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            label="Code"
            name="code"
            rules={[
              { required: true, message: 'Please input code!' },
              {
                validator: (rule, value, cb) => {
                    !value || Number(value.length) != 8
                        ? cb("Code must be = 8 character")
                        : cb();
                }
              }
            ]}
          >
            <Input placeholder='8 character required.'/>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Add
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      
    </>
  );
}

export default Referrals;