import React, { useState, useEffect, useCallback, Fragment } from 'react';
import { BrowserRouter as Router, Route, Link, useHistory } from 'react-router-dom';
import Breadcrumb from './Breadcrumb';
import 'antd/dist/antd.css';
import './style.css';
import { Row, Card, Pagination, Button, Tag, Table, Space, Input, Col, Select, message, Modal, Form, DatePicker } from 'antd';

import Navbar from '../../layouts/Navbar';
import Sidenav from '../../layouts/Sidenav';
import TransactionLogs from '../../../config/api/transactionLog';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import type { DatePickerProps } from 'antd';
import moment from 'moment';

const { RangePicker } = DatePicker;


const Bonus = () => {

  let authToken:any = window.localStorage.getItem('tokenId');

  const [users, setUser] = useState<any>();
  const [totalRow, setTotalRow] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isAddQuote, setIsAddQuote] = useState(false);
  const [snapshotId, setSnapshotId] = useState(0);
  const [form] = Form.useForm();

  let endDate:any = new Date();
  let today:any = new Date();
  // let startDate:any = new Date(today.setDate(today.getDate() - 7));

  function getMonday(d:any) {
    d = new Date(d);
    var day = d.getDay(),
      diff = d.getDate() - day + (day == 0 ? -6 : -1); // adjust when day is sunday
    return new Date(d.setDate(diff));
  }

  let startDate:any = getMonday(today);

  // const [startTime, setStartTime] = useState<any>(startDate);
  // const [endTime, setEndTime] = useState<any>(endDate); 
  const [configApy, setConfigApy] = useState<any>();


  const [param, setParam] = useState(
    {
      page: 1,
      size: 10,
      startTime: '',
      endTime: ''
    }
  );

  const getSnapshotsList = async () => {
    let res:any = await TransactionLogs.getSnapshotsList(param);
    setTotalRow(parseInt(res.data.total));
    if(res.status === 200 && res.data.data.length > 0){
      setUser(res.data.data);
    }else{
      setUser([]);
    }
  };

  useEffect(() => {
    getSnapshotsList();
  }, [param]);

  

  const showUpdateReward = (id:any)=>{
    form.resetFields();
    setSnapshotId(id);
    form.setFieldsValue({
      'snapshotId': id
    })
    setIsAddQuote(true);
  }

  
  

  const getConfigApy = async () => {
      await TransactionLogs.getConfigApy().then((res)=>{
          if(res.status == 200 && res.data.data){
          setConfigApy(res.data.data);
          }
      }).catch();
  }
  useEffect(() => {
      getConfigApy();
  }, []);

  function getFormattedDate(date:any) {
    let year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, '0');
    let day = date.getDate().toString().padStart(2, '0');
  
    return  year + '-' + month + '-' +day;
}

  const columns: any = [
    {
      title: 'Date',
      dataIndex: 'created_at',
      render: (text:any, record:any) => {
                // let dateTime = formatDate(record?.lastTimeAdd?.time);
                // let date:any = new Date(record?.created_at).toLocaleDateString('en-US', {
                   
                //     day: '2-digit',
                //     month: '2-digit',
                //     year: 'numeric',
                //     hour12: false
                // })
				return (
					<div className="text-gray">{ getFormattedDate(new Date(record?.created_at)) }</div>
				)
			}
    },
    {
      title: 'Total staked',
      dataIndex: 'hela_total_stake',
      render: (text:any, record:any) => {
                
				return (
					<div className="text-info">
            {new Intl.NumberFormat("ja-JP").format(record?.hela_total_stake)}
          </div>
				)
			}
    },
    {
      title: 'Inflow | Outflow',
      dataIndex: 'hela_net_inflow',
      render: (text:any, record:any) => {
                
				return (
					<div className="text-info">
            {new Intl.NumberFormat("ja-JP").format(record?.hela_net_inflow)}
          </div>
				)
			}
    },
    {
      title: 'Reward',
      dataIndex: 'hela_reward',
      render: (text:any, record:any) => {
                
				return (
					<div className="text-info">
            {new Intl.NumberFormat("ja-JP").format(record?.hela_reward)}
          </div>
				)
			}
    },
    {
      title: 'Daily percent return',
      dataIndex: 'hela_daily_percent_return',
      render: (text:any, record:any) => {
                
				return (
					<div className="text-info">
            {new Intl.NumberFormat("ja-JP").format(record?.hela_daily_percent_return * 100)} %
          </div>
				)
			}
    },
    
    {
      title: '',
      dataIndex: 'action',
      render: (text:any, record:any) => {
                
				return (
					<div className="text-info">
            {record.hela_status == "PENDING" && (
              <Button type="primary" ghost onClick={()=>showUpdateReward(record?.id)}>Update reward</Button>
            )}
          </div>
				)
			}
    },
  ];


  const onFinish = async(values: any) => {
    
    let data:any = {
      "reward": Number(values?.reward),
      "snapshotId": Number(values?.snapshotId),
    }
    await TransactionLogs.updateSnapshotBonus(data).then((res:any)=>{
      console.log('res', res);
      message.success('Add Successfully!');
      getSnapshotsList();
      setIsAddQuote(false);
    }).catch((error)=>{
      message.error('Add Failed!');
    });
  
  };


  const handlePublicBonus = async() => {
    
    if(!authToken || authToken == null){
      message.error('Login expried time.');
      return;
    }
    
    await TransactionLogs.publicBonus().then((res:any)=>{
      message.success('Successfully!');
      getSnapshotsList();
    }).catch((error)=>{
      message.error('Failed!');
      if(error?.status == 401){
        window.localStorage.removeItem('tokenId');
        window.localStorage.removeItem('loginTime');
        window.location.replace('/login');    
      }
    });
  
  };


  const weekFormat = 'YYYY-MM-DD 00:00:00';
  const customWeekStartEndFormat: DatePickerProps['format'] = value =>
  `${moment(value).startOf('week').format(weekFormat)} ~ ${moment(value).endOf('week').format(weekFormat)}`;

  // const onChangeWeek: DatePickerProps['onChange'] = (date, dateString) => {

  //   let dateList:any = dateString.split(" ~ ");

  //   let startDate:any = new Date(dateList[0]);
  //   let endDate:any = new Date(dateList[1]+' 23:59:59');

  //   if(date && startDate && endDate){
  //     setParam({
  //         ...param,
  //         startTime: startDate.toISOString(),
  //         endTime: endDate.toISOString()
  //     });
  //   }else{
  //     setParam({
  //         ...param,
  //         startTime: '',
  //         endTime: ''
  //     });
  //   }
  // };
  const onRangeChange = (dates:any) => {  

   

    let d1:any = dates ? new Date(dates[0]?._d.toDateString()) : '';
    let d2:any = dates ? new Date(dates[1]?._d.toDateString()) : '';

    let time:any = d2.getTime() + (23*60*60*1000);

    let d3 = new Date(time);
    
    let startDate:any = dates ? d1?.toISOString() : '';
    let endDate:any = dates ? d3?.toISOString() : '';
    
   
    if(startDate != '' && endDate != ''){
      setParam({
        ...param,
        startTime: startDate,
        endTime: endDate
      });
    }else{
      setParam({
          ...param,
          startTime: '',
          endTime: ''
      });
    }
  }

  const onChange_Pagi = (pageNumber:any) => {
    console.log(pageNumber);
    setParam({
      ...param,
      page: pageNumber
    });
  };

  return (
    <>
      <div className="body ms-body ms-aside-left-open ms-primary-theme ms-has-quickbar" id="body">
        <Sidenav />
        <main className="body-content">
          <Navbar />
          <div className="ms-content-wrapper">
            <div className="row">
              <Breadcrumb />
              <Card 
                title="Bonus" style={{ width: '100%' }}
                extra={<>
                  <RangePicker onChange={onRangeChange}  />
                  {/* <DatePicker defaultValue={moment()} format={customWeekStartEndFormat} picker="week" onChange={onChangeWeek}/> */}
                </>}
              >
                
                <p style={{color: 'blue'}}>
                  Avg APY : <span style={{fontSize: '18px', fontWeight: '600', marginRight: '15px'}}>{configApy?.sns_hela_str}</span> 
                  <Button type="primary" danger onClick={()=>handlePublicBonus()}>Publish bonus</Button>
                </p>
                <div className="table-responsive">
                    <Table columns={columns} dataSource={users} pagination={false}/>

                    {Number(totalRow) > Number(param?.size) && (
                      <Pagination
                          current={param.page}
                          defaultCurrent={1}
                          total={totalRow}
                          pageSize={param.size}
                          onChange={onChange_Pagi}
                          showSizeChanger={false}
                          style={{marginTop: '15px'}}
                          className="pagiation-custom mt-15"
                      />
                    )}
                </div>
              </Card>
            </div>
          </div>
        </main>
      </div>
      <Modal title="Update reward" footer={false} visible={isAddQuote} onOk={()=>{ setIsAddQuote(false); form.resetFields()}} onCancel={()=>{ setIsAddQuote(false); form.resetFields()}}>
        <Form
          name="basic"
          form={form}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            label="Reward"
            name="reward"
            rules={[{ required: true, message: 'Please input reward!' }]}
          >
            <Input placeholder='Ex: 10'/>
          </Form.Item>
          <Form.Item
            name="snapshotId"
            hidden
          >
            <Input placeholder='Ex: 10'/>
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Confirm
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default Bonus;