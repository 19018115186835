import React, { useState, useEffect, useCallback, Fragment } from 'react';
import { BrowserRouter as Router, Route, Link, useHistory } from 'react-router-dom';
import Breadcrumb from './Breadcrumb';
import 'antd/dist/antd.css';
import './style.css';
import { Row, Card, Pagination, Button, Tag, Table, Space, Input, Col, Select, message, Modal, Form, Checkbox } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import Navbar from '../../layouts/Navbar';
import Sidenav from '../../layouts/Sidenav';
import TransactionLogs from '../../../config/api/transactionLog';
import CopyToClipboard from 'react-copy-to-clipboard';

const TradingPlatformApyCreate = () => {

  const [users, setUser] = useState<any>();
  const [loading, setLoading] = useState(false);
  let authToken:any = window.localStorage.getItem('tokenId');

  const [form] = Form.useForm();


  const onFinish = async(values: any) => {

    if(!authToken){
      message.error('Invalid!. Please login again');
      return;
    }

    
    let data:any = {
      "name": values?.name,
      "logo": values?.logo ? values?.logo : '',
      "description": values?.description,
      "allocations": Number(values?.allocations)
    }
    await TransactionLogs.createTradingPlatform(data).then((res:any)=>{

      message.success('Successfully!');
      form.resetFields();

    }).catch((error)=>{
      message.error('Failed!');
    });
    
    

  };

    return (
      <>
        <div className="body ms-body ms-aside-left-open ms-primary-theme ms-has-quickbar" id="body">
          <Sidenav />
          <main className="body-content">
            <Navbar />
            <div className="ms-content-wrapper">
              <div className="row">
                <Breadcrumb />
                <Card 
                  title="Trading Platform" style={{ width: '100%' }}
                >
                  <Form
                    name="basic"
                    form={form}
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 20 }}
                    onFinish={onFinish}
                    autoComplete="off"
                  >
                    <Form.Item
                      label="Platform Name"
                      name="name"
                      rules={[{ required: true, message: 'Please input name!' }]}
                    >
                      <Input type=""  placeholder='Ex: 0xx.xxx' style={{maxWidth: '500px'}}/>
                    </Form.Item>
                    
                    <Form.Item
                      label="Logo URL"
                      name="logo"
                    >
                      <Input type="url" placeholder='Ex: 10' style={{maxWidth: '500px'}}/>
                    </Form.Item>
                    <Form.Item
                      label="Description"
                      name="description"
                    >
                      <Input type="" placeholder='Ex:...' style={{maxWidth: '500px'}}/>
                    </Form.Item>
                    <Form.Item
                      label="Allocations"
                      name="allocations"
                      initialValue={0}
                      rules={[{ required: true, message: 'Please input amount!' }]}
                    >
                      <Input type="" placeholder='Ex: 10' style={{maxWidth: '500px'}}/>
                    </Form.Item>
                    
                    
                    <Form.Item>
                      <Button type="primary" htmlType="submit">
                        Confirm
                      </Button>
                    </Form.Item>
                  </Form>
                </Card>
              </div>
            </div>
          </main>
        </div>
      </>
    );
}

export default TradingPlatformApyCreate;