import React, { useState, useEffect, useCallback, Fragment } from 'react';
import { BrowserRouter as Router, Route, Link, useHistory } from 'react-router-dom';
import Breadcrumb from './Breadcrumb';
import 'antd/dist/antd.css';
import './style.css';
import { Row, Card, Pagination, Button, Tag, Table, Space, Input, Col, Select, message, Modal, Form } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import Navbar from '../../layouts/Navbar';
import Sidenav from '../../layouts/Sidenav';
import TransactionLogs from '../../../config/api/transactionLog';
import CopyToClipboard from 'react-copy-to-clipboard';

const Pools = () => {

  const [users, setUser] = useState<any>();
  const [loading, setLoading] = useState(false);
  let authToken:any = window.localStorage.getItem('tokenId');

  const [configApy, setConfigApy] = useState<any>();
  const [form] = Form.useForm();

    const getConfigApy = async () => {
        await TransactionLogs.getConfigApy().then((res)=>{
            if(res.status == 200 && res.data.data){
            setConfigApy(res.data.data);
            }
        }).catch();
    }
    useEffect(() => {
        getConfigApy();
    }, [])

    useEffect(() => {
        if(configApy){
          console.log('configApy', configApy);
          form.setFieldsValue({
              'apr': configApy?.apy,
              'point': configApy?.usd_to_point,
          });
        }
    }, [configApy])

  const onFinish = async(values: any) => {

    if(!authToken){
      message.error('Invalid!. Please login again');
      return;
    }
    
    let data:any = {
      "apy": values?.apr ? values?.apr : '',
      "usd_to_point": Number(values?.point),
    }
    await TransactionLogs.updateApr(data).then((res:any)=>{

      message.success('Update Successfully!');
      form.resetFields();
      getConfigApy();

    }).catch((error)=>{
      message.error('Update Failed!');
    });
    
    

  };

    return (
      <>
        <div className="body ms-body ms-aside-left-open ms-primary-theme ms-has-quickbar" id="body">
          <Sidenav />
          <main className="body-content">
            <Navbar />
            <div className="ms-content-wrapper">
              <div className="row">
                <Breadcrumb />
                <Card 
                  title="Pools" style={{ width: '100%' }}
                >
                  <h1 style={{textAlign: 'center'}}>Coming Soon</h1>
                  {/* <Form
                    name="basic"
                    form={form}
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 20 }}
                    onFinish={onFinish}
                    autoComplete="off"
                  >
                    <Form.Item
                      label="Point per 1 USDC/USDT"
                      name="point"
                      rules={[{ required: true, message: 'Please input point!' }]}
                    >
                      <Input type="number" placeholder='Ex: 1' style={{maxWidth: '500px'}}/>
                    </Form.Item>
                  
                    <Form.Item>
                      <Button type="primary" htmlType="submit">
                        Confirm
                      </Button>
                    </Form.Item>
                  </Form> */}
                </Card>
              </div>
            </div>
          </main>
        </div>
      </>
    );
}

export default Pools;