import React, { useState } from 'react';
import { message, Card, Table, Button, Spin, Input } from 'antd';
import Navbar from '../../layouts/Navbar';
import Sidenav from '../../layouts/Sidenav';
import Breadcrumb from './Breadcrumb';
import AdminApi from '../../../config/api/adminApi';
import 'antd/dist/antd.css';
import './style.css';

const DownloadReports = () => {
  const [loadingKeys, setLoadingKeys] = useState<string[]>([]);

  const handleDownloadClick = async (key: string, handler: () => Promise<void>) => {
    setLoadingKeys((prev) => [...prev, key]);
    try {
      await handler();
    } catch (error) {
      message.error(`Download failed, please try again later`);
    } finally {
      setLoadingKeys((prev) => prev.filter((k) => k !== key));
    }
  };

  const [param, setParam] = useState({ address: '' });

  const dataSource = [
    { key: '1', title: 'All historical deposit history', handler: () => AdminApi.exportDepositHistory(param.address, 'AllDepositHistory') },
    { key: '2', title: 'All unstake history', handler: () => AdminApi.exportUnstakeHistory(param.address, 'AllUnstakeHistory') },
    { key: '3', title: 'All claim history', handler: () => AdminApi.exportClaimRewardHistory(param.address, 'AllClaimHistory') },
    { key: '4', title: 'All point history', handler: () => AdminApi.exportPointHistory(param.address, 'AllPointHistory') },
    { key: '5', title: 'Summary Stake, Rewards, and Unstake', handler: () => AdminApi.exportSummaryHistory(param.address, 'SummaryHistory') },
  ];

  const onSearch = (value: string) => {
    setParam((prev) => ({ ...prev, address: value }));
  };

  const columns = [
    { title: 'Item', dataIndex: 'key', key: 'key', width: '5%' },
    { title: 'Report', dataIndex: 'title', key: 'title' },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: '15%',
      render: (_: any, record: any) => (
        <Button
          type="primary"
          className='button-spin'
          onClick={() => handleDownloadClick(record.key, record.handler)}
        >
          {loadingKeys.includes(record.key) ? <Spin size='small' /> : 'Download'}
        </Button>
      ),
    },
  ];

  return (
    <div className="body ms-body ms-aside-left-open ms-primary-theme ms-has-quickbar" id="body">
      <Sidenav />
      <main className="body-content">
        <Navbar />
        <div className="ms-content-wrapper">
          <div className="row">
            <Breadcrumb />
            <Card title="Download reports" style={{ width: '100%' }}
              extra={<>
                <div className='d-flex'>
                  <Input.Search
                    placeholder="Input wallet address"
                    onSearch={onSearch}
                    onChange={(e) => setParam({ address: e.target.value })}
                    enterButton="Apply filter"
                  />
                </div>
              </>}
            >
              <Table dataSource={dataSource} columns={columns} pagination={false} />
            </Card>
          </div>
        </div>
      </main>
    </div>
  );
};

export default DownloadReports;
