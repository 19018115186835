import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, NavLink } from 'react-bootstrap';
import Scrollbar from 'react-perfect-scrollbar';

class Navbar extends Component {
    navToggle = () => {
        document.getElementById('body').classList.toggle('ms-aside-left-open');
        document.getElementById('ms-side-nav').classList.toggle('ms-aside-open');
        document.getElementById('overlayleft').classList.toggle('d-block');
    }
    activityToggle = () => {
        document.getElementById('ms-recent-activity').classList.toggle('ms-aside-open');
        document.getElementById('overlayright').classList.toggle('d-block');
    }
    optionsToggle = () => {
        document.getElementById('ms-nav-options').classList.toggle('ms-slide-down');
    }

    onLogout = ()=>{
        window.localStorage.removeItem('tokenId');
        window.localStorage.removeItem('loginTime');
        window.location.replace('/login');
    }
    
    render() {
        return (
            <nav className="navbar ms-navbar">
                <div className="ms-aside-toggler ms-toggler pl-0" data-target="#ms-side-nav" data-toggle="slideLeft" onClick={this.navToggle}>
                    <span className="ms-toggler-bar bg-primary" />
                    <span className="ms-toggler-bar bg-primary" />
                    <span className="ms-toggler-bar bg-primary" />
                </div>
                <div className="logo-sn logo-sm ms-d-block-sm">
                    <Link className="pl-0 ml-0 text-center navbar-brand mr-0" to="/">AIRDROP BOT</Link>
                </div>
                <ul className="ms-nav-list ms-inline mb-0" id="ms-nav-options">

                    <Dropdown className="ms-nav-item ms-nav-user">
                        <Dropdown.Toggle as={NavLink} id="userDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="p-0 toggle-icon-none"> <img className="ms-user-img ms-img-round float-right" src={process.env.PUBLIC_URL + "/assets/img/people/people-5.jpg"} alt="people" /> </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu-right user-dropdown" aria-labelledby="userDropdown">

                            <li className="dropdown-menu-footer">
                                <a className="media fs-14 p-2" onClick={this.onLogout}> <span><i className="flaticon-shut-down mr-2" /> Logout</span> </a>
                            </li>
                        </Dropdown.Menu>
                    </Dropdown>
                </ul>

            </nav>
        );
    }
}

export default Navbar;