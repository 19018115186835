import React, { useState, useEffect, useCallback, Fragment } from 'react';
import { BrowserRouter as Router, Route, Link, useHistory } from 'react-router-dom';
import Breadcrumb from './Breadcrumb';
import 'antd/dist/antd.css';
import './style.css';
import { Row, Card, Pagination, Button, Tag, Table, Space, Input, Col, Select, message, Modal, Form, DatePicker, Spin } from 'antd';

import Navbar from '../../layouts/Navbar';
import Sidenav from '../../layouts/Sidenav';
import TransactionLogs from '../../../config/api/transactionLog';

import axios, { AxiosRequestConfig } from 'axios';
import fs from 'fs';

const { RangePicker } = DatePicker;
const { Search } = Input;

const TransactionReport = () => {

  const [transactions, setTransactions] = useState<any>();
  const [totalRow, setTotalRow] = useState(0);
  const [loading, setLoading] = useState(false);
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [form] = Form.useForm();
  let authToken:any = window.localStorage.getItem('tokenId');


  const [param, setParam] = useState(
    {
      page: 1,
      size: 20,
      wallet: '',
      startTime: '',
      endTime: ''
    }
  );

  const getTransactionReports = async () => {
    setLoading(true);
    let res:any = await TransactionLogs.getTransactionReports(param);
    setTotalRow(parseInt(res.data.total));
    if(res.status === 200 && res.data.data.length > 0){
      setTransactions(res.data.data);
      setLoading(false);
    }else{
      setTransactions([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    getTransactionReports();
  }, [param]);

  function getFormattedDate(date:any) {
    let year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, '0');
    let day = date.getDate().toString().padStart(2, '0');
  
    return  year + '-' + month + '-' +day;
  }

  const columns: any = [
    {
      title: 'Date',
      dataIndex: 'created_at',
      render: (text:any, record:any) => {

        const createdAt = new Date(record?.created_at);
 
        let datTime = createdAt.getTime();
        let newDateTime = datTime - (8*60*60);
        let newDate = new Date(newDateTime); 
				return (
					<div className="text-gray">
            { getFormattedDate(newDate) }
          </div>
				)
			}
    },
    {
      title: 'User',
      dataIndex: 'user_address',
     
      render: (text:any, record:any) => {
        return (
          <div className="text-black">
            {record?.user_address ? `${record?.user_address.substring(0, 8)}...${record?.user_address.substring(record?.user_address.length - 8)}` : "---"}
          </div>
        )}
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      render: (text:any, record:any) => {
                
				return (
					<div className="text-info">
            {new Intl.NumberFormat("ja-JP").format(record?.amount)}
          </div>
				)
			}
    },
    {
      title: 'Hash',
      dataIndex: 'transaction_hash',
     
      render: (text:any, record:any) => {
        return (
          <div className="text-black">
            {record?.transaction_hash ? `${record?.transaction_hash.substring(0, 8)}...${record?.transaction_hash.substring(record?.transaction_hash.length - 8)}` : "---"}
          </div>
        )}
    },
    
    {
      title: 'Token',
      dataIndex: 'token_address',
      render: (text:any, record:any) => {
        let token = 'USDT';
        if(record?.token_address != '0x55d398326f99059ff775485246999027b3197955'){
          token = 'USDC';
        }
				return (
					<div className="text-info">
            {token}
          </div>
				)
			}
    }
  ];


  const handleExport = async() => {
    
    let data:any = {
      startTime: startTime,
      endTime: endTime
    }

    const headers = {
      'Content-Type': 'blob',
      "Authorization": `Bearer ${authToken}`,
    };
    const config: AxiosRequestConfig = {
      method: 'POST', 
      url: `${process.env.REACT_APP_API_URL}api/admins/export-transaction-reports`,
      params: data,
      responseType: 'arraybuffer', 
      headers
    };
    
    try {
        const response = await axios(config);
        
        const outputFilename = `transaction_report_${Date.now()}.xlsx`;

        // If you want to download file automatically using link attribute.
        const url = URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', outputFilename);
        document.body.appendChild(link);
        link.click();
        message.success('Successfully!');
    } catch (error:any) {
        console.log('error', error);
    }

    
    // await TransactionLogs.exportTransaction(data).then((res:any)=>{
    //   const url = window.URL.createObjectURL(new Blob([res.data]));

    //   const link = document.createElement('a');

    //   link.href = url;
    //   link.setAttribute('download', `transaction_report.xlsx`);
    //   document.body.appendChild(link);
    //   link.click();

    //   console.log('res----', res);
    //   message.success('Successfully!');
   
    // }).catch((error)=>{
    //   message.error('Failed!');
    // });

  
  };


  const onRangeChange = (dates:any) => {  
    
    let startDate:any = dates ? dates[0]?._d?.toISOString() : '';
    let endDate:any = dates ? dates[1]?._d?.toISOString() : '';
    
   
    if(startDate != '' && endDate != ''){
      setStartTime(startDate);
      setEndTime(endDate);
      setParam({
        ...param,
        startTime: startDate,
        endTime: endDate
      });
    }else{
      setStartTime('');
      setEndTime('');
      setParam({
          ...param,
          startTime: '',
          endTime: ''
      });
    }
  }

  const onChange_Pagi = (pageNumber:any) => {
    console.log(pageNumber);
    setParam({
      ...param,
      page: pageNumber
    });
  };

  const onSearch: any = (value:any, _e:any, info:any) => {
      console.log(info?.source, value)
      setParam({
            ...param,
            wallet: value
        });
    };

  return (
    <>
      <div className="body ms-body ms-aside-left-open ms-primary-theme ms-has-quickbar" id="body">
        <Sidenav />
        <main className="body-content">
          <Navbar />
          <div className="ms-content-wrapper">
            <div className="row">
              <Breadcrumb />
              <Card 
                title="Transaction reports" style={{ width: '100%' }}
                extra={<>
                  <div className='d-flex'>
                    <Search placeholder="input wallet" onSearch={onSearch} enterButton />
                  <RangePicker onChange={onRangeChange}  />
                  </div>
                  {/* <DatePicker defaultValue={moment()} format={customWeekStartEndFormat} picker="week" onChange={onChangeWeek}/> */}
                </>}
              >
                <p style={{color: 'blue'}}>
                  <Button type="primary" danger disabled={startTime == '' || endTime == ''} onClick={()=>handleExport()}>Export</Button>
                </p>

                <div className="">
                    {loading ? (
                      <><div className='text-center'><Spin size='large'/></div></>
                    ):(
                      <>
                        <Table columns={columns} dataSource={transactions} pagination={false}/>
                        {Number(totalRow) > Number(param?.size) && (
                          <Pagination
                              current={param.page}
                              defaultCurrent={1}
                              total={totalRow}
                              pageSize={param.size}
                              onChange={onChange_Pagi}
                              showSizeChanger={false}
                              style={{marginTop: '15px'}}
                              className="pagiation-custom mt-15"
                          />
                        )}
                      </>
                    )}
                    
                </div>
              </Card>
            </div>
          </div>
        </main>
      </div>
      
    </>
  );
}

export default TransactionReport;