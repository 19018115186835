import React, { useState, useEffect, useCallback, Fragment } from 'react';
import { BrowserRouter as Router, Route, Link, useHistory } from 'react-router-dom';
import Breadcrumb from './Breadcrumb';
import 'antd/dist/antd.css';
import './style.css';
import { Row, Card, Pagination, Button, Tag, Table, Space, Input, Col, Select, message, Modal, Form, DatePicker } from 'antd';

import Navbar from '../../layouts/Navbar';
import Sidenav from '../../layouts/Sidenav';
import TransactionLogs from '../../../config/api/transactionLog';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import type { DatePickerProps } from 'antd';
import moment from 'moment';

const { RangePicker } = DatePicker;


const Snapshots = () => {

  let authToken:any = window.localStorage.getItem('tokenId');

  const [users, setUser] = useState<any>();
  const [totalRow, setTotalRow] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isAddQuote, setIsAddQuote] = useState(false);
  const [snapshotId, setSnapshotId] = useState(0);
  const [form] = Form.useForm();

  let endDate:any = new Date();
  let today:any = new Date();
  // let startDate:any = new Date(today.setDate(today.getDate() - 7));

  function getMonday(d:any) {
    d = new Date(d);
    var day = d.getDay(),
      diff = d.getDate() - day + (day == 0 ? -6 : -1); // adjust when day is sunday
    return new Date(d.setDate(diff));
  }

  let startDate:any = getMonday(today);

  // const [startTime, setStartTime] = useState<any>(startDate);
  // const [endTime, setEndTime] = useState<any>(endDate); 
  const [configApy, setConfigApy] = useState<any>();


  const [param, setParam] = useState(
    {
      page: 1,
      size: 500,
      startTime: '',
      endTime: ''
    }
  );

  const getSnapshotsList = async () => {
    let res:any = await TransactionLogs.getSnapshotsList(param);
    setTotalRow(parseInt(res.data.total));
    if(res.status === 200 && res.data.data.length > 0){
      setUser(res.data.data);
    }else{
      setUser([]);
    }
  };

  useEffect(() => {
    getSnapshotsList();
  }, [param]);

  // useEffect(() => {
  //   if(param.startTime == '' && param?.endTime == ''){
    
  //     setParam({
  //         ...param,
  //         startTime: startDate,
  //         endTime: endDate
  //     });
  //   }
  // }, [param, startDate, endDate]);

  

  const showAddQuote = (id:any)=>{
    console.log('snapshotId', id);
    form.resetFields();
    setSnapshotId(id);
    form.setFieldsValue({
      'snapshotId': id
    })
    setIsAddQuote(true);
  }

  const enableApy = async (id:any, status:any) => {
    let data = {
      "use_apy": status
    }
      await TransactionLogs.enableApy(id, data).then((res)=>{
          if(res.status == 200 && res.data.data){
            message.success('Successfully!');
            getSnapshotsList();
          }
      }).catch();
  }
  

  const getConfigApy = async () => {
      await TransactionLogs.getConfigApy().then((res)=>{
          if(res.status == 200 && res.data.data){
          setConfigApy(res.data.data);
          }
      }).catch();
  }
  useEffect(() => {
      getConfigApy();
  }, []);

  function getFormattedDate(date:any) {
    let year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, '0');
    let day = date.getDate().toString().padStart(2, '0');
  
    return  year + '-' + month + '-' +day;
}

  const columns: any = [
    {
      title: 'Date',
      dataIndex: 'created_at',
      render: (text:any, record:any) => {
				return (
					<div className="text-gray">{ getFormattedDate(new Date(record?.created_at)) }</div>
				)
			}
    },
    {
      title: 'Total staked',
      dataIndex: 'total_stake',
      render: (text:any, record:any) => {
                
				return (
					<div className="text-info">
            {new Intl.NumberFormat("ja-JP").format(record?.total_stake)}
          </div>
				)
			}
    },
    {
      title: 'Net inflow',
      dataIndex: 'net_inflow',
      render: (text:any, record:any) => {
                
				return (
					<div className="text-info">
            {new Intl.NumberFormat("ja-JP").format(record?.net_inflow)}
          </div>
				)
			}
    },
    {
      title: 'Reward',
      dataIndex: 'reward',
      render: (text:any, record:any) => {
                
				return (
					<div className="text-info">
            {new Intl.NumberFormat("ja-JP").format(record?.reward)}
          </div>
				)
			}
    },
    {
      title: 'Daily percent return',
      dataIndex: 'daily_percent_return',
      render: (text:any, record:any) => {
                
				return (
					<div className="text-info">
            {new Intl.NumberFormat("ja-JP").format(record?.daily_percent_return * 100)} %
          </div>
				)
			}
    },
    {
      title: '',
      dataIndex: 'action',
      render: (text:any, record:any) => {
                
				return (
					<div className="text-info">
            {record.status == "PENDING" && record?.reward == 0 && (
              <Button type="primary" ghost onClick={()=>showAddQuote(record?.id)}>Update reward</Button>
            )}
            {record.use_apy && (
              <Button type="ghost" style={{marginLeft: '5px'}} danger onClick={()=>enableApy(record?.id, false)}>Disbale</Button>
            )}
            {!record.use_apy && (
              <Button type="primary" style={{marginLeft: '5px'}} onClick={()=>enableApy(record?.id, true)}>Enable</Button>
            )}
          </div>
				)
			}
    },
  ];


  const onFinish = async(values: any) => {
    
    let data:any = {
      "reward": Number(values?.reward),
      "snapshotId": Number(values?.snapshotId),
    }
    await TransactionLogs.updateSnapshotReward(data).then((res:any)=>{
      console.log('res', res);
      message.success('Add Successfully!');
      getSnapshotsList();
      setIsAddQuote(false);
    }).catch((error)=>{
      message.error('Add Failed!');
    });
  
  };


  const handlePublic = async() => {
    
    if(!authToken){
      message.error('Login expried time.');
      return;
    }
    
    await TransactionLogs.publicApy().then((res:any)=>{
      message.success('Successfully!');
      getSnapshotsList();
    }).catch((error)=>{
      message.error('Failed!');
      if(error?.status == 401){
        window.localStorage.removeItem('tokenId');
        window.localStorage.removeItem('loginTime');
        window.location.replace('/login');    
      }
    });
  
  };

  const onRangeChange = (dates:any) => {  

   

    let d1:any = dates ? new Date(dates[0]?._d.toDateString()) : '';
    let d2:any = dates ? new Date(dates[1]?._d.toDateString()) : '';

    let time:any = d2.getTime() + (23*60*60*1000);

    let d3 = new Date(time);
    
    let startDate:any = dates ? d1?.toISOString() : '';
    let endDate:any = dates ? d3?.toISOString() : '';
    
   
    if(startDate != '' && endDate != ''){
      setParam({
        ...param,
        startTime: startDate,
        endTime: endDate
      });
    }else{
      setParam({
          ...param,
          startTime: '',
          endTime: ''
      });
    }
  }

  return (
    <>
      <div className="body ms-body ms-aside-left-open ms-primary-theme ms-has-quickbar" id="body">
        <Sidenav />
        <main className="body-content">
          <Navbar />
          <div className="ms-content-wrapper">
            <div className="row">
              <Breadcrumb />
              <Card 
                title="Snapshots" style={{ width: '100%' }}
                extra={<>
                  <RangePicker onChange={onRangeChange}  />
                </>}
              >
                
                <p style={{color: 'blue'}}>
                  Avg APY : <span style={{fontSize: '18px', fontWeight: '600', marginRight: '15px'}}>{configApy?.sns_avg_apy_str}</span> 
                  <Button type="primary" danger onClick={()=>handlePublic()}>Publish</Button>
                </p>
                <div className="table-responsive">
                    <Table columns={columns} dataSource={users} />
                </div>
              </Card>
            </div>
          </div>
        </main>
      </div>
      <Modal title="Update reward" footer={false} visible={isAddQuote} onOk={()=>{ setIsAddQuote(false); form.resetFields()}} onCancel={()=>{ setIsAddQuote(false); form.resetFields()}}>
        <Form
          name="basic"
          form={form}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            label="Reward"
            name="reward"
            rules={[{ required: true, message: 'Please input reward!' }]}
          >
            <Input placeholder='Ex: 10'/>
          </Form.Item>
          <Form.Item
            name="snapshotId"
            hidden
          >
            <Input placeholder='Ex: 10'/>
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Confirm
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default Snapshots;