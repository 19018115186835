import React, { Component, Fragment } from 'react';

import Navbar from '../layouts/Navbar';

import Sidenav from '../layouts/Sidenav';
import Content from '../sections/home/Content';

class HomePage extends Component {
    render() {
        return (
            <>

                <div className="body ms-body ms-aside-left-open ms-primary-theme ms-has-quickbar" id="body">

                    <Sidenav />

                    <main className="body-content">
                        <Navbar />
                        <Content />
                    </main>

                </div>
            </>
        );
    }
}

export default HomePage;