import React, { useState, useEffect, useCallback, Fragment } from 'react';
import { BrowserRouter as Router, Route, Link, useHistory } from 'react-router-dom';
import Breadcrumb from './Breadcrumb';
import 'antd/dist/antd.css';
import './style.css';
import { Row, Card, Pagination, Button, Tag, Table, Input, Col, Select, message, Modal, Form, DatePicker } from 'antd';

import Navbar from '../../layouts/Navbar';
import Sidenav from '../../layouts/Sidenav';
import TransactionLogs from '../../../config/api/transactionLog';


const StakingPlatformList = () => {

  let authToken:any = window.localStorage.getItem('tokenId');

  const [stakingPlatform, setStakingPlatform] = useState<any>();
  const [totalRow, setTotalRow] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isAddQuote, setIsAddQuote] = useState(false);
  const [snapshotId, setSnapshotId] = useState(0);
  const [form] = Form.useForm();



  const [param, setParam] = useState(
    {
      page: 1,
      size: 10
    }
  );

  const getStakingPlatform = async () => {
    let res:any = await TransactionLogs.getStakingPlatformList(param);
    setTotalRow(parseInt(res.data.total));
    if(res.status === 200 && res.data.data.length > 0){
      setStakingPlatform(res.data.data);
    }else{
      setStakingPlatform([]);
    }
  };

  useEffect(() => {
    getStakingPlatform();
  }, [param]);

  

  const showUpdateReward = (record:any)=>{
    form.resetFields();

    form.setFieldsValue({
      'id': record?.id,
      'stablehodl_apy': record?.stablehodl_apy,
      'ethena_apy': record?.ethena_apy,
      'ethefi_apy': record?.ethefi_apy,
      's_dai_apy': record?.s_dai_apy,
      'created_at': new Date(record?.created_at),
    })
    setIsAddQuote(true);
  }

  function getFormattedDate(date:any) {
    let year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, '0');
    let day = date.getDate().toString().padStart(2, '0');
  
    return  year + '-' + month + '-' +day;
  }

  const columns: any = [
    {
      title: 'StableHodl',
      dataIndex: 'stablehodl_apy',
      render: (text:any, record:any) => {
                
				return (
					<div className="text-info">
            {new Intl.NumberFormat("ja-JP").format(record?.stablehodl_apy)}
          </div>
				)
			}
    },
    {
      title: 'Ethena',
      dataIndex: 'ethena_apy',
      render: (text:any, record:any) => {
                
				return (
					<div className="text-info">
            {new Intl.NumberFormat("ja-JP").format(record?.ethena_apy)}
          </div>
				)
			}
    },
    {
      title: 'Ethefi',
      dataIndex: 'ethefi_apy',
      render: (text:any, record:any) => {
                
				return (
					<div className="text-info">
            {new Intl.NumberFormat("ja-JP").format(record?.ethefi_apy)}
          </div>
				)
			}
    },
    {
      title: 'sDai',
      dataIndex: 's_dai_apy',
      render: (text:any, record:any) => {
                
				return (
					<div className="text-info">
            {new Intl.NumberFormat("ja-JP").format(record?.s_dai_apy)}
          </div>
				)
			}
    },
    {
      title: 'Date',
      dataIndex: 'created_at',
      render: (text:any, record:any) => {

        const createdAt = new Date(record?.created_at);
 
        let datTime = createdAt.getTime();
        let newDateTime = datTime - (8*60*60);
        let newDate = new Date(newDateTime); 
				return (
					<div className="text-gray">
            { getFormattedDate(newDate) }
          </div>
				)
			}
    },
    {
      title: '',
      dataIndex: 'action',
      render: (text:any, record:any) => {
                
				return (
					<div className="text-info">
            <Button type="primary" ghost onClick={()=>showUpdateReward(record)}>Update</Button>
            <Button type="primary" style={{marginLeft: '5px'}} danger onClick={()=>onDelete(record?.id)}>Delete</Button>
          </div>
				)
			}
    },
  ];


  const onFinish = async(values: any) => {

    const event = new Date(values?.created_at);
    
    let data:any = {
      "stablehodl_apy": Number(values?.stablehodl_apy),
      "ethena_apy": Number(values?.ethena_apy),
      "ethefi_apy": Number(values?.ethefi_apy),
      "s_dai_apy": Number(values?.s_dai_apy),
      "created_at": event.toISOString(),
    }
    await TransactionLogs.updateStakingPlatform(values?.id, data).then((res:any)=>{
      console.log('res', res);
      message.success('Successfully!');
      getStakingPlatform();
      setIsAddQuote(false);
    }).catch((error)=>{
      message.error('Failed!');
    });
  
  };
  const onDelete = async(id: any) => {
    
    
    await TransactionLogs.deleteStakingPlatform(id).then((res:any)=>{
      console.log('res', res);
      message.success('Successfully!');
      getStakingPlatform();
      setIsAddQuote(false);
    }).catch((error)=>{
      message.error('Failed!');
    });
  
  };

  const onChange_Pagi = (pageNumber:any) => {
    console.log(pageNumber);
    setParam({
      ...param,
      page: pageNumber
    });
  };

  return (
    <>
      <div className="body ms-body ms-aside-left-open ms-primary-theme ms-has-quickbar" id="body">
        <Sidenav />
        <main className="body-content">
          <Navbar />
          <div className="ms-content-wrapper">
            <div className="row">
              <Breadcrumb />
              <Card 
                title="List" style={{ width: '100%' }}
                
              >
                
                
                <div className="table-responsive">
                    <Table columns={columns} dataSource={stakingPlatform} pagination={false}/>

                    {Number(totalRow) > Number(param?.size) && (
                      <Pagination
                          current={param.page}
                          defaultCurrent={1}
                          total={totalRow}
                          pageSize={param.size}
                          onChange={onChange_Pagi}
                          showSizeChanger={false}
                          style={{marginTop: '15px'}}
                          className="pagiation-custom mt-15"
                      />
                    )}
                </div>
              </Card>
            </div>
          </div>
        </main>
      </div>
      <Modal title="Update reward" footer={false} visible={isAddQuote} onOk={()=>{ setIsAddQuote(false); form.resetFields()}} onCancel={()=>{ setIsAddQuote(false); form.resetFields()}}>
        <Form
          name="basic"
          form={form}
          
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            label="Platform Name"
            name="id"
          >
            <Input hidden type=""  placeholder='Ex: 0xx.xxx' style={{maxWidth: '500px'}}/>
          </Form.Item>
          <Form.Item
            label="Stablehodl apy"
            name="stablehodl_apy"
            initialValue={0}
            rules={[{ required: true, message: 'Please input amount!' }]}
          >
            <Input type=""  placeholder='Ex: 0xx.xxx' style={{maxWidth: '500px'}}/>
          </Form.Item>
          <Form.Item
            label="Ethena apy"
            name="ethena_apy"
            initialValue={0}
            rules={[{ required: true, message: 'Please input amount!' }]}
          >
            <Input type="" placeholder='Ex: 10' style={{maxWidth: '500px'}}/>
          </Form.Item>
          <Form.Item
            label="Ethefi apy"
            name="ethefi_apy"
            initialValue={0}
            rules={[{ required: true, message: 'Please input amount!' }]}
          >
            <Input type="" placeholder='Ex: 10' style={{maxWidth: '500px'}}/>
          </Form.Item>
          <Form.Item
            label="S Dai apy"
            name="s_dai_apy"
            initialValue={0}
            rules={[{ required: true, message: 'Please input amount!' }]}
          >
            <Input type="" placeholder='Ex: 10' style={{maxWidth: '500px'}}/>
          </Form.Item>
          <Form.Item
            label="Date"
            name="created_at"
            rules={[{ required: true, message: 'Please input date!' }]}
          >
            <Input type="date" placeholder='Ex: 10' style={{maxWidth: '500px'}}/>
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Confirm
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default StakingPlatformList;