/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable array-callback-return */
import 'antd/dist/antd.css';
import { Col, Row, Select } from 'antd';
import React, { Fragment, useEffect, useState } from 'react';
import AdminSummary from '../../../config/api/adminSummary';


const systemStat = () => {

    const [dataCheck, setDataCheck] = useState<any>();


    const getPoolCheck = async () => {
        let res: any = await AdminSummary.getPoolCheck();
        if (res.status == 200 && res.data.data) {
            setDataCheck(res.data.data);
        } else {
            setDataCheck(null);
        }
    };

    useEffect(() => {
        getPoolCheck();
    }, []);


    return (
        <div className="col-md-12">
            <div className="ms-panel">
                <div className="ms-panel-body ms-news-flash-container">
                    <div className="ms-news-update">

                    </div>
                    <div className='' style={{ width: '100%', paddingLeft: '130px' }}>

                        <div className="row dashboard-panel">
                            <Fragment>
                                <div className="col-xl-3 col-sm-6 col-md-6 item">
                                    <div className="ms-card ms-widget has-graph-full-width ms-infographics-widget mb-0">
                                        <div className="ms-card-body media pt-1">
                                            <i className="cc ETH" />
                                            <div className="media-body">
                                                <a href='https://helascan.io/address/0x10057f7D9897414d1c84C886e16566DcE8d66777' target='_blank'><span className='text-info'><strong>Unstake Pool Balance (smart contract)</strong></span></a>
                                                <h2 className='text-danger'>{dataCheck?.unstake_balance ? new Intl.NumberFormat("ja-JP").format(dataCheck?.unstake_balance) : 0}</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-sm-6 col-md-6 item">
                                    <div className="ms-card ms-widget has-graph-full-width ms-infographics-widget mb-0">
                                        <div className="ms-card-body media pt-1">
                                            <i className="cc ETH" />
                                            <div className="media-body">
                                                <span className='text-info'><strong>Waiting Unstake from all users (database)</strong></span>
                                                <h2 className='text-danger'>{dataCheck?.waiting_unstake ? new Intl.NumberFormat("ja-JP").format(dataCheck?.waiting_unstake) : 0}</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-sm-6 col-md-6 item">
                                    <div className="ms-card ms-widget has-graph-full-width ms-infographics-widget mb-0">
                                        <div className="ms-card-body media pt-1">
                                            <i className="cc ETH" />
                                            <div className="media-body">
                                                <span className='text-info'><strong>Need to Deposit to Unstake Pool</strong></span>
                                                <h2 className='text-danger'>{dataCheck?.need_to_deposit ? new Intl.NumberFormat("ja-JP").format(dataCheck?.need_to_deposit) : 0}</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-sm-6 col-md-6 item">
                                    <div className="ms-card ms-widget has-graph-full-width ms-infographics-widget mb-0">
                                        <div className="ms-card-body media pt-1">
                                            <i className="cc ETH" />
                                            <div className="media-body">
                                                <a href='https://helascan.io/address/0x109D3042a3c682F94107b3818e93b3aDE2A47544' target='_blank'><span className='text-info'><strong>Claim Yield Pool Balance <br />(smart contract) </strong></span></a>
                                                <h2 className='text-danger'>{dataCheck?.claim_yield_balance ? new Intl.NumberFormat("ja-JP").format(dataCheck?.claim_yield_balance) : 0}</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-sm-6 col-md-6 item">
                                    <div className="ms-card ms-widget has-graph-full-width ms-infographics-widget mb-0">
                                        <div className="ms-card-body media pt-1">
                                            <i className="cc ETH" />
                                            <div className="media-body">
                                                <span className='text-info'><strong>Total Unclaim Yield from all users <br />(database)</strong></span>
                                                <h2 className='text-danger'>{dataCheck?.total_unclaim_yield ? new Intl.NumberFormat("ja-JP").format(dataCheck?.total_unclaim_yield) : 0}</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Fragment>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}
export default systemStat;