import React, { useState, useEffect, useCallback, Fragment } from 'react';
import { BrowserRouter as Router, Route, Link, useHistory } from 'react-router-dom';
import Breadcrumb from './Breadcrumb';
import 'antd/dist/antd.css';
import './style.css';
import { Row, Card, Pagination, Button, Tag, Table, Space, Input, Col, Select, message, Modal, Form, DatePicker, Spin } from 'antd';

import Navbar from '../../layouts/Navbar';
import Sidenav from '../../layouts/Sidenav';
import TransactionLogs from '../../../config/api/transactionLog';

import axios, { AxiosRequestConfig } from 'axios';
import fs from 'fs';

const { RangePicker } = DatePicker;


const BalanceReport = () => {

  const [balances, setBalances] = useState<any>();
  const [totalRow, setTotalRow] = useState(0);
  const [loading, setLoading] = useState(false);
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [form] = Form.useForm();
  let authToken:any = window.localStorage.getItem('tokenId');


  const [param, setParam] = useState(
    {
      page: 1,
      size: 20,
      date: '',
    }
  );

  const getBalancesReports = async () => {
    setLoading(true);
    let res:any = await TransactionLogs.getBalancesReports(param);
    setTotalRow(parseInt(res.data.total));
    if(res.status === 200 && res.data.data.length > 0){
      setBalances(res.data.data);
      setLoading(false);
    }else{
      setBalances([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    getBalancesReports();
  }, [param]);

  function getFormattedDate(date:any) {
    let year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, '0');
    let day = date.getDate().toString().padStart(2, '0');
  
    return  year + '-' + month + '-' +day;
  }

  const columns: any = [
    
    {
      title: 'User',
      dataIndex: 'user_address',
     
      render: (text:any, record:any) => {
        return (
          <div className="text-black">
            {record?.user_address ? `${record?.user_address.substring(0, 8)}...${record?.user_address.substring(record?.user_address.length - 8)}` : "---"}
          </div>
        )}
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      render: (text:any, record:any) => {
                
				return (
					<div className="text-info">
            {new Intl.NumberFormat("ja-JP").format(record?.amount)}
          </div>
				)
			}
    },
    {
      title: 'USDT Amount',
      dataIndex: 'usdt_amount',
      render: (text:any, record:any) => {
                
				return (
					<div className="text-info">
            {new Intl.NumberFormat("ja-JP").format(record?.usdt_amount)}
          </div>
				)
			}
    },
    {
      title: 'USDC Amount',
      dataIndex: 'usdc_amount',
      render: (text:any, record:any) => {
                
				return (
					<div className="text-info">
            {new Intl.NumberFormat("ja-JP").format(record?.usdc_amount)}
          </div>
				)
			}
    },
    {
      title: 'Reward',
      dataIndex: 'reward',
      render: (text:any, record:any) => {
                
				return (
					<div className="text-info">
            {new Intl.NumberFormat("ja-JP").format(record?.reward)}
          </div>
				)
			}
    },
    {
      title: 'Claimed reward',
      dataIndex: 'claimed_reward',
      render: (text:any, record:any) => {
                
				return (
					<div className="text-info">
            {record?.claimed_reward ? new Intl.NumberFormat("ja-JP").format(record?.claimed_reward) : 0}
          </div>
				)
			}
    },
    {
      title: 'Total unstake',
      dataIndex: 'total_unstake',
      render: (text:any, record:any) => {
                
				return (
					<div className="text-info">
            {record?.total_unstake ? new Intl.NumberFormat("ja-JP").format(record?.total_unstake) : 0}
          </div>
				)
			}
    },
    {
      title: 'Total withdraw',
      dataIndex: 'total_withdraw',
      render: (text:any, record:any) => {
                
				return (
					<div className="text-info">
            {record?.total_withdraw ? new Intl.NumberFormat("ja-JP").format(record?.total_withdraw) : 0}
          </div>
				)
			}
    },
    
  ];


  const handleExport = async() => {
    
    let data:any = {
      date: startTime
    }

    const headers = {
      'Content-Type': 'blob',
      "Authorization": `Bearer ${authToken}`,
    };
    const config: AxiosRequestConfig = {
      method: 'POST', 
      url: `${process.env.REACT_APP_API_URL}api/admins/export-balance-reports`,
      params: data,
      responseType: 'arraybuffer', 
      headers
    };
    
    try {
        const response = await axios(config);
        
        const outputFilename = `balance_report_${Date.now()}.xlsx`;

        // If you want to download file automatically using link attribute.
        const url = URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', outputFilename);
        document.body.appendChild(link);
        link.click();
        message.success('Successfully!');
    } catch (error:any) {
        console.log('error', error);
    }

    
    // await TransactionLogs.exportTransaction(data).then((res:any)=>{
    //   const url = window.URL.createObjectURL(new Blob([res.data]));

    //   const link = document.createElement('a');

    //   link.href = url;
    //   link.setAttribute('download', `transaction_report.xlsx`);
    //   document.body.appendChild(link);
    //   link.click();

    //   console.log('res----', res);
    //   message.success('Successfully!');
   
    // }).catch((error)=>{
    //   message.error('Failed!');
    // });

  
  };


  const onRangeChange = (date:any) => {  
    
    let startDate:any = date ? date?.toISOString() : '';
    
   
    if(startDate != ''){
      setStartTime(startDate);
      setParam({
        ...param,
        date: startDate
      });
    }else{
      setStartTime('');
      setParam({
          ...param,
          date: '',
      });
    }
  }

  const onChange_Pagi = (pageNumber:any) => {
    console.log(pageNumber);
    setParam({
      ...param,
      page: pageNumber
    });
  };

  return (
    <>
      <div className="body ms-body ms-aside-left-open ms-primary-theme ms-has-quickbar" id="body">
        <Sidenav />
        <main className="body-content">
          <Navbar />
          <div className="ms-content-wrapper">
            <div className="row">
              <Breadcrumb />
              <Card 
                title="Balance reports" style={{ width: '100%' }}
                extra={<>
                  <DatePicker onChange={onRangeChange}  />
                  {/* <DatePicker defaultValue={moment()} format={customWeekStartEndFormat} picker="week" onChange={onChangeWeek}/> */}
                </>}
              >
                <p style={{color: 'blue'}}>
                  <Button type="primary" danger disabled={startTime == ''} onClick={()=>handleExport()}>Export</Button>
                </p>

                <div className="">
                    {loading ? (
                      <><div className='text-center'><Spin size='large'/></div></>
                    ):(
                      <>
                        <Table columns={columns} dataSource={balances} pagination={false}/>
                        {Number(totalRow) > Number(param?.size) && (
                          <Pagination
                              current={param.page}
                              defaultCurrent={1}
                              total={totalRow}
                              pageSize={param.size}
                              onChange={onChange_Pagi}
                              showSizeChanger={false}
                              style={{marginTop: '15px'}}
                              className="pagiation-custom mt-15"
                          />
                        )}
                      </>
                    )}
                    
                </div>
              </Card>
            </div>
          </div>
        </main>
      </div>
      
    </>
  );
}

export default BalanceReport;