import React, { Component } from 'react';
import Infographics from './Infographics';
import Crypto from './Crypto';
import SystemStat from './SystemStat';
import Bitcoingraph from './Bitcoingraph';
import Highlightsy from './Highlightsy';

class Content extends Component {
    render() {
        return (
            <div className="ms-content-wrapper">
                <div className="row">
                    <SystemStat/> 
                    {/* <Crypto/>
                    <Infographics/>
                    <Bitcoingraph/>
                    <Highlightsy/> */}
                    
                </div>
            </div>
        );
    }
}

export default Content;