import React, { useState, useEffect, useCallback, Fragment } from 'react';
import { BrowserRouter as Router, Route, Link, useHistory } from 'react-router-dom';
import Breadcrumb from './Breadcrumb';
import 'antd/dist/antd.css';
import './style.css';
import { Row, Card, Pagination, Button, Tag, Table, Space, Input, Col, Select, message, Modal, Form, DatePicker } from 'antd';

import Navbar from '../../layouts/Navbar';
import Sidenav from '../../layouts/Sidenav';
import TransactionLogs from '../../../config/api/transactionLog';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import type { DatePickerProps } from 'antd';
import moment from 'moment';

const { RangePicker } = DatePicker;


const TradingPlatformList = () => {

  let authToken:any = window.localStorage.getItem('tokenId');

  const [tradingPlatform, setTradingPlatform] = useState<any>();
  const [totalRow, setTotalRow] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isAddQuote, setIsAddQuote] = useState(false);
  const [snapshotId, setSnapshotId] = useState(0);
  const [form] = Form.useForm();



  const [param, setParam] = useState(
    {
      page: 1,
      size: 10
    }
  );

  const getTradingPlatform = async () => {
    let res:any = await TransactionLogs.getTradingPlatformsList(param);
    setTotalRow(parseInt(res.data.total));
    if(res.status === 200 && res.data.data.length > 0){
      setTradingPlatform(res.data.data);
    }else{
      setTradingPlatform([]);
    }
  };

  useEffect(() => {
    getTradingPlatform();
  }, [param]);

  

  const showUpdateReward = (record:any)=>{
    form.resetFields();

    form.setFieldsValue({
      'id': record?.id,
      'name': record?.name,
      'logo': record?.logo,
      'description': record?.description,
      'allocations': record?.allocations,
    })
    setIsAddQuote(true);
  }

  
  

  

  function getFormattedDate(date:any) {
    let year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, '0');
    let day = date.getDate().toString().padStart(2, '0');
  
    return  year + '-' + month + '-' +day;
}

  const columns: any = [
    // {
    //   title: 'Date',
    //   dataIndex: 'created_at',
    //   render: (text:any, record:any) => {
    //             // let dateTime = formatDate(record?.lastTimeAdd?.time);
    //             // let date:any = new Date(record?.created_at).toLocaleDateString('en-US', {
                   
    //             //     day: '2-digit',
    //             //     month: '2-digit',
    //             //     year: 'numeric',
    //             //     hour12: false
    //             // })
		// 		return (
		// 			<div className="text-gray">{ getFormattedDate(new Date(record?.created_at)) }</div>
		// 		)
		// 	}
    // },
    {
      title: 'Name',
      dataIndex: 'name',
      render: (text:any, record:any) => {
                
				return (
					<div className="text-info">
            {record?.name}
          </div>
				)
			}
    },
    {
      title: 'Allocations',
      dataIndex: 'allocations',
      render: (text:any, record:any) => {
                
				return (
					<div className="text-info">
            {new Intl.NumberFormat("ja-JP").format(record?.allocations)}
          </div>
				)
			}
    },
    {
      title: 'Description',
      dataIndex: 'description',
      render: (text:any, record:any) => {
                
				return (
					<div className="text-info">
            {record?.description}
          </div>
				)
			}
    },
    {
      title: 'Logo',
      dataIndex: 'logo',
      render: (text:any, record:any) => {
                
				return (
					<div className="text-info">
            <img width={25} src={record?.logo} />
          </div>
				)
			}
    },
    
    {
      title: '',
      dataIndex: 'action',
      render: (text:any, record:any) => {
                
				return (
					<div className="text-info">
            <Button type="primary" ghost onClick={()=>showUpdateReward(record)}>Update</Button>
            <Button type="primary" style={{marginLeft: '5px'}} danger onClick={()=>onDelete(record?.id)}>Delete</Button>
          </div>
				)
			}
    },
  ];


  const onFinish = async(values: any) => {
    
    let data:any = {
      "name": values?.name,
      "logo": values?.logo ? values?.logo : '',
      "description": values?.description,
      "allocations": Number(values?.allocations),
      "active": true
    }
    await TransactionLogs.updateTradingPlatform(values?.id, data).then((res:any)=>{
      console.log('res', res);
      message.success('Successfully!');
      getTradingPlatform();
      setIsAddQuote(false);
    }).catch((error)=>{
      message.error('Failed!');
    });
  
  };
  const onDelete = async(id: any) => {
    
    
    await TransactionLogs.deleteTradingPlatform(id).then((res:any)=>{
      console.log('res', res);
      message.success('Successfully!');
      getTradingPlatform();
      setIsAddQuote(false);
    }).catch((error)=>{
      message.error('Failed!');
    });
  
  };

  const onChange_Pagi = (pageNumber:any) => {
    console.log(pageNumber);
    setParam({
      ...param,
      page: pageNumber
    });
  };

  return (
    <>
      <div className="body ms-body ms-aside-left-open ms-primary-theme ms-has-quickbar" id="body">
        <Sidenav />
        <main className="body-content">
          <Navbar />
          <div className="ms-content-wrapper">
            <div className="row">
              <Breadcrumb />
              <Card 
                title="List" style={{ width: '100%' }}
                
              >
                
                
                <div className="table-responsive">
                    <Table columns={columns} dataSource={tradingPlatform} pagination={false}/>

                    {Number(totalRow) > Number(param?.size) && (
                      <Pagination
                          current={param.page}
                          defaultCurrent={1}
                          total={totalRow}
                          pageSize={param.size}
                          onChange={onChange_Pagi}
                          showSizeChanger={false}
                          style={{marginTop: '15px'}}
                          className="pagiation-custom mt-15"
                      />
                    )}
                </div>
              </Card>
            </div>
          </div>
        </main>
      </div>
      <Modal title="Update reward" footer={false} visible={isAddQuote} onOk={()=>{ setIsAddQuote(false); form.resetFields()}} onCancel={()=>{ setIsAddQuote(false); form.resetFields()}}>
        <Form
          name="basic"
          form={form}
          
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            label="Platform Name"
            name="id"
          >
            <Input hidden type=""  placeholder='Ex: 0xx.xxx' style={{maxWidth: '500px'}}/>
          </Form.Item>
          <Form.Item
            label="Platform Name"
            name="name"
            rules={[{ required: true, message: 'Please input name!' }]}
          >
            <Input type=""  placeholder='Ex: 0xx.xxx' style={{maxWidth: '500px'}}/>
          </Form.Item>
          <Form.Item
            label="Logo URL"
            name="logo"
          >
            <Input type="url" placeholder='Ex: 10' style={{maxWidth: '500px'}}/>
          </Form.Item>
          <Form.Item
            label="Description"
            name="description"
          >
            <Input type="" placeholder='Ex:...' style={{maxWidth: '500px'}}/>
          </Form.Item>
          <Form.Item
            label="Allocations"
            name="allocations"
            initialValue={0}
            rules={[{ required: true, message: 'Please input amount!' }]}
          >
            <Input type="" placeholder='Ex: 10' style={{maxWidth: '500px'}}/>
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Confirm
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default TradingPlatformList;