import React, { useState, useEffect, useCallback, Fragment } from 'react';
import { BrowserRouter as Router, Route, Link, useHistory } from 'react-router-dom';
import Breadcrumb from './Breadcrumb';
import 'antd/dist/antd.css';
import './style.css';
import { Row, Card, Pagination, Button, Tag, Table, Space, Input, Col, Select, message, Modal, Form, DatePicker } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import Navbar from '../../layouts/Navbar';
import Sidenav from '../../layouts/Sidenav';
import TransactionLogs from '../../../config/api/transactionLog';
import CopyToClipboard from 'react-copy-to-clipboard';

const { Search } = Input;

const ApyEstimate = () => {

  const [users, setUser] = useState<any>();
  const [totalRow, setTotalRow] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isAddQuote, setIsAddQuote] = useState(false);
  const [snapshotId, setSnapshotId] = useState(0);
  const [form] = Form.useForm();
  const [startTime, setStartTime] = useState<any>("");
  const [endTime, setEndTime] = useState<any>(""); 

  const [param, setParam] = useState(
    {
      page: 1,
      size: 500,
      wallet: null
    }
  );

  const getApyEstimateList = async () => {
    let res:any = await TransactionLogs.getApyEstimateList(param);
    setTotalRow(parseInt(res.data.total));
    if(res.status === 200 && res.data.data.length > 0){
      setUser(res.data.data);
    }else{
      setUser([]);
    }
  };

  useEffect(() => {
    getApyEstimateList();
  }, [param]);

  const onChange_Pagi = (pageNumber:any) => {
        setParam({
            ...param,
            page: pageNumber - 1
        });
    };

    const onSearch: any = (value:any, _e:any, info:any) => {
      console.log(info?.source, value)
      setParam({
            ...param,
            wallet: value
        });
    };

  const showAddQuote = (id:any)=>{
    console.log('snapshotId', id);
    form.resetFields();
    setSnapshotId(id);
    form.setFieldsValue({
      'snapshotId': id
    })
    setIsAddQuote(true);
  }

  const columns: any = [
    {
      title: 'Wallet',
      dataIndex: 'wallet',
     
      render: (text:any, record:any) => {
        return (
            <div className="text-info">
                {record?.wallet ? `${record?.wallet.substring(0, 8)}...${record?.wallet.substring(record?.wallet.length - 8)}` : "---"}
               
            </div>
        )}
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      defaultSortOrder: 'descend',
      sorter: (a:any, b:any) => a.amount - b.amount,
      render: (text:any, record:any) => {
                
				return (
					<div className="text-info">
            {new Intl.NumberFormat("ja-JP").format(record?.amount)}
          </div>
				)
			}
    },
    
  ];



  return (
    <>
      <div className="body ms-body ms-aside-left-open ms-primary-theme ms-has-quickbar" id="body">
        <Sidenav />
        <main className="body-content">
          <Navbar />
          <div className="ms-content-wrapper">
            <div className="row">
              <Breadcrumb />
              <Card 
                title="Apy Estimate" style={{ width: '100%' }}
                extra={<>
                  <Search placeholder="input search text" onSearch={onSearch} enterButton />
                </>}
              >
                <div className="table-responsive">
                    <Table columns={columns} dataSource={users}/>
                </div>
                {/* {totalRow > 9 && (
                    <Pagination
                        current={param.page + 1}
                        defaultCurrent={0}
                        total={totalRow}
                        pageSize={param.size}
                        onChange={onChange_Pagi}
                        showSizeChanger={false}
                        style={{marginTop: '15px'}}
                        className="pagiation-custom mt-15"
                    />
                )} */}
              </Card>
            </div>
          </div>
        </main>
      </div>
      
    </>
  );
}

export default ApyEstimate;