import React from 'react';
import { BrowserRouter as Router, Route, Link, Switch, useLocation, useHistory } from 'react-router-dom';
import Header from './components/common/header';

function App() {
  const history = useHistory();

  var hours = 1;
  var now:any = new Date().getTime();
  var loginTime:any = localStorage.getItem('loginTime');
  if (loginTime) {
      if(now-loginTime > hours*60*60*1000) {
          localStorage.clear()
          history.push({
            pathname: `/login`,
            search: ''
          });
      }
  }

  let tokenId = window.localStorage.getItem('tokenId');
  
  if (!tokenId) {
    history.push({
      pathname: `/login`,
      search: ''
    });
  }


  return (
    <>
      <Header />
    </>
  );
}

export default App;
