import { message } from "antd";

async function exportFile(endpoint: string, address?: string, prefixfilename?: string) {
    const requestParams = address ? { address } : {};
    const baseUrl = process.env.REACT_APP_ADMIN_API_URL || "https://stablehodl.com";
    const options = {
        method: 'PUT',
        headers: {
            'accept': '*/*',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestParams)
    };

    const url = baseUrl + endpoint;
    try {
        const response = await fetch(url, options);
        if (!response.ok) {
            message.error(`Download failed, please try again later`);
            return;
        }
        const formattedDate = new Date().toISOString().replace(/[-:]/g, '').split('.')[0].replace('T', '_').slice(0, -2);
        const outputFilename = `${prefixfilename}_${formattedDate}UTC.xlsx`;
        const blob = await response.blob();
        const urlObject = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = urlObject;
        a.download = outputFilename;
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(urlObject);
        message.success('Successfully!');
    } catch (error) {
        message.error(`Download failed, please try again later`);
        console.error('Error:', error);
    }
}


const createExportFunction = (endpoint: string) => (address: string, prefixfilename: string) => exportFile(endpoint, address, prefixfilename);

export const AdminApi = {
    exportDepositHistory: createExportFunction('/admin-api/stake/export'),
    exportUnstakeHistory: createExportFunction('/admin-api/unstake/export'),
    exportClaimRewardHistory: createExportFunction('/admin-api/claim-reward/export'),
    exportPointHistory: createExportFunction('/admin-api/point/export'),
    exportSummaryHistory: createExportFunction('/admin-api/summary/export'),
};

export default AdminApi;
